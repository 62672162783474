$xsmall-width: 0;
$small-width: 576px;
$medium-width: 768px;
$large-width: 992px;
$xlarge-width: 1279px;
$xxlarge-width: 1600px;

@mixin small-down {
  @media screen and (min-width: #{$xsmall-width}) and (max-width: #{$small-width}) {
    @content;
  }
}

@mixin large-down {
  @media screen and (min-width: #{$xsmall-width}) and (max-width: #{$large-width}) {
    @content;
  }
}

@mixin small {
  @media screen and (min-width: #{$small-width}) {
    @content;
  }
}

@mixin medium {
  @media screen and (min-width: #{$medium-width}) {
    @content;
  }
}

@mixin large {
  @media screen and (min-width: #{$large-width}) {
    @content;
  }
}

@mixin xlarge {
  @media screen and (min-width: #{$xlarge-width}) {
    @content;
  }
}

@mixin xxlarge {
  @media screen and (min-width: #{$xxlarge-width}) {
    @content;
  }
}
