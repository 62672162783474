//===================================
// VARIABLES
//===================================

$text: #000;


//SAND
$s1: #F5F3F0;
$s2: #EAE2D7;
$s3: #DECDB2;
$s4: rgba(208, 183, 145, 0.76);

$s150: #FAF9F7;


$s220: hsl(30, 33, 98);
$s230: hsl(209, 51, 92);
$s250: hsl(211, 52, 87);
$s270: hsl(211, 51, 82);


//OCEAN
$o1: hsl(220, 45, 40);
$o2: #99BBDE;
$o4: hsl(231, 60, 24);
$o3: #45A2FF;
$o490: #2F3A71;

$o330: hsl(210, 100, 89);
$o350: hsl(210, 100, 80);
$o370: #7DBEFF;

$o220: hsl(212, 48, 95);
$o230: hsl(209, 51, 92);
$o250: hsl(211, 52, 87);
$o270: hsl(211, 51, 82);

$ofill: hsl(231, 60, 40);


//VELVET
$v1: rgb(110, 70, 149);
$v2: hsl(279, 37, 74);
$v3: hsl(280, 83, 69);
$v4: hsl(260, 53, 23);
$v490: #45326A;

$v220: hsl(276, 38, 95);
$v230: hsl(280, 38, 92);
$v250: hsl(278, 38, 87);
$v270: hsl(279, 37, 82);

$v370: #D89AF6;


//RASPBERRY
$r1: hsl(335, 48, 40);
$r2: #E3AAB3;
$r3: #F2637B;
$r4: #4F143B;
$r490: #612C4F;
$r370: #F692A3;

$r220: hsl(349, 48, 95);
$r230: hsl(353, 52, 94);
$r250: hsl(351, 50, 89);
$r270: hsl(350, 50, 84);

$r330: hsl(350, 84, 90);
$r350: hsl(350, 84, 80);


//FORREST
$f1: hsl(125, 25, 35);
$f2: hsl(110, 36, 74);
$f3: hsl(110, 51, 57);
$f4: #173621;
$f490: #2E4A37;
$f370: #99DA8C;
$f220: hsl(113, 33, 95);
$f330: hsl(111, 51, 87);
$f350: hsl(110, 52, 79);
$f250: #D5E9D1;

$brown: #373030;


:root {
  --light: #{$s1};
  --dark: #{$text};
}

[data-theme="default"] {
  --light: #{$s1};
  // --dark: #2F3A71;
  --dark: #{$o4};
  // --dark: #{$r4};
  --base: #{$s1};

  --gridItemBase: #{$s2};

  --scrollspy: #{$s1};

  --progressBar: #{$o1};
  // --progressBase: #{$s2};
  --progressBase: rgba(255, 255, 255, .5);

  --boxBase: #{$s2};

  --modalBox: #{$s2};
  --modalBase: #{$s1};
}


[data-theme="purple"] {
  --light: #{$v220};
  --medium: #{$v370};
  --dark: #{$v4};
  --base: #{$s1};
  --converse: #{$r370};

  --scrollspy: #{$v4};

  --accent: #{$v1};
  --modalBox: #{$v250};
  --modalBase: #{$v220};
}

.purple {
  --light: #{$v220};
  --medium: #{$v370};
  --dark: #{$v4};
  --base: #{$s1};

  --scrollspy: #{$v4};

  --modalBox: #{$v250};
  --modalBase: #{$v220};
}

[data-theme="red"] {
  --light: #{$r220};
  --medium: #{$r370};
  --dark: #{$r4};
  --base: #{$s1};

  --scrollspy: #{$r4};

  --progressBar: #{$r1};
  --progressBase: rgba(255, 255, 255, .5);

  --gridItemFill: #{$r350};
  --gridItemBase: #{$r330};

  --boxBase: #{$r330};

  --modalBox: #FDDFE5;
  --modalBase: #{$r220};
}

.red {
  --light: #{$r220};
  --medium: #{$r370};
  --dark: #{$r4};
  --base: #{$s1};

  --scrollspy: #{$r4};

  --gridItemFill: #{$r350};
  --gridItemBase: #{$r330};
  --modalBox: #{$r250};
  --modalBase: #{$r220};
}

[data-theme="green"] {
  --light: #{$f220};
  --medium: #{$f370};
  --dark: #{$f4};
  --base: #{$s1};

  --gridItemBase: #{$f330};
  --gridItemFill: #{$f350};

  --progressBar: #{$f1};
  --progressBase: rgba(255, 255, 255, .5);

  --scrollspy: #{$f4};

  --modalBox: #{$f330};
  --modalBase: #{$f220};
}

.green {
  --light: #{$f250};
  --medium: #{$f370};
  --dark: #{$f4};

  --scrollspy: #{$f4};
}

[data-theme="blue"] {
  --light: #{$o220};
  --medium: #{$o3};
  --dark: #{$o4};
  --base: #{$s1};

  --overlayFill: #{$o370};

  --scrollspy: #{$o4};

  --gridItemBase: #{$o330};
  --gridItemFill: #{$o350};

  --progressBar: #{$o1};
  --progressBase: rgba(255, 255, 255, .5);

  --modalBox: #{$o250};
  --modalBase: #{$o220};
}

.blue {
  --light: #{$o220};
  --medium: #{$o3};
  --dark: #{$o4};

  --overlayFill: #{$o370};

  --scrollspy: #{$o4};

  --gridItemFill: #{$o350};
  --gridItemBase: #{$o330};
  --modalBox: #{$o250};
  --modalBase: #{$o220};
}

$headerHeight: 72px;
