// Custom.scss

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../../../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here

$box-shadow:                  0 .5rem 2rem rgba(#000, .2);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1400px,
  xxl: 1600px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1280px,
  xxl: 1440px
);


$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer * 1,
  4: $spacer * 1.5,
  5: $spacer * 2,
  6: $spacer * 3,
  7: $spacer * 5,
  8: $spacer * 6,
  9: $spacer * 7.5
);

$theme-colors: (
  // "primary":    black,
  // "secondary":  $secondary,
  // "success":    $success,
  // "info":       $info,
  // "warning":    $warning,
  // "danger":     $danger,
  "light":      $s150,
  "dark":       $o4
);



$progress-height:                   16px;
// $progress-font-size:                $font-size-base * .75;
$progress-bg:                       var(--progressBase);
$progress-border-radius:            16px;
$progress-box-shadow:               0;
$progress-bar-color:                transparent;
$progress-bar-bg:                   var(--progressBar);
$progress-bar-animation-timing:     1s linear infinite;
$progress-bar-transition:           width .6s ease;

$list-group-bg:                     transparent;
$list-group-color:                  inherit;
$list-group-border-color:           inherit;

$list-group-item-padding-y:         $spacer * .2;

$border-radius: 2rem;
// $border-radius:               12px;
$border-color:                rgba(0,0,0,.07);

$border-radius-sm:            8px;
$border-radius-lg:            24px;
$border-radius-pill:          50rem;

$modal-dialog-margin-y-sm-up:       40px;
$modal-content-border-width:        0;
$modal-inner-padding:               $spacer*3;
$modal-header-padding-y:            24px;
$modal-header-padding-x:            24px;
$modal-content-bg:                  $s150;

$modal-content-box-shadow-sm-up:    $box-shadow;

$modal-sm:                          500px;
$modal-md:                          800px;
$modal-lg:                          1000px;


$modal-fade-transform:              translate(0, 100%);
$modal-show-transform:              none;
$modal-transition:                  transform .8s cubic-bezier(.23,1,.32,1);
// $modal-scale-transform:             scale(1.02);



$tooltip-font-size:                 13px;
$tooltip-max-width:                 320px;
$tooltip-color:                     white;
$tooltip-bg:                        black;
// $tooltip-border-radius:             $border-radius;
// $tooltip-opacity:                   .9;
$tooltip-padding-y:                 $spacer * 1;
$tooltip-padding-x:                 $spacer * 1;
// $tooltip-arrow-width:               .8rem;
// $tooltip-arrow-height:              .4rem;



$enable-shadows:               true;
$modal-title-line-height: 1.2;

$card-height: 100%;

$utilities: (
  "opacity": (
    property: opacity,
    values: (
      0: 0,
      25: .25,
      50: .5,
      75: .75,
      100: 1,
    )
  ),

  "min-viewport-height": (
      property: min-height,
      class: min-vh,
      values: (
      100: 100vh,
      75: 75vh,
      50: 50vh,
      )
    ),
);

$btn-link-color:              currentColor;
$btn-link-hover-color:        currentColor;
$btn-link-disabled-color:     currentColor;

$btn-border-width: 0;



$thumbnail-box-shadow: 0;
$thumbnail-bg:   transparent;
$thumbnail-border-width: 0;


$btn-transition:              color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, transform .15s ease-in-out;

$btn-padding-y-sm:            6px;
$btn-padding-x-sm:            12px;

$btn-padding-x:               24px;
$btn-padding-y:               12px;

$btn-padding-y-lg:            20px;
$btn-padding-x-lg:            40px;

// $btn-border-radius:           100px;
$btn-border-radius-sm:        40px;
$btn-border-radius-lg:        12px;
$btn-font-weight:             500;
$btn-font-size:               16px;
$btn-font-size-lg:            1em;
// $btn-link-color:              white;

$btn-font-weight:             500;
// $btn-box-shadow:              0;
// $btn-focus-width:             $input-btn-focus-width;
// $btn-focus-box-shadow:        0;
$btn-disabled-opacity:        .65;
$btn-active-box-shadow:       none;

$input-border-radius:         12px;
$input-border-radius-lg:      calc($border-radius / 2);
// $input-border-width:                    0;
$input-box-shadow:                      0;
$input-border-color:                    transparent;
$input-focus-border-color:              transparent;

$input-padding-y:                       16px;
$input-padding-x:                       24px;
$input-font-size-lg:                    1.2rem;
$input-font-weight:                     500;

$input-transition:                      border-color .15s ease-in-out, box-shadow .15s ease-in-out;

$input-bg:                              rgba(0,0,0,.3);
$input-focus-bg:                        rgba(0,0,0,.5);
$input-focus-border-color:              white;
$input-color:                           currentColor;
$input-placeholder-color:               rgba(255,255,255,.5);
$form-select-indicator-color:       white;

$form-select-padding-y:             16px;
$form-select-padding-x:             24px;
$form-select-box-shadow:                      0;
$form-select-border-radius:         12px;

$form-check-input-bg:                     transparent;

$form-feedback-icon-invalid:        none;



$form-label-font-weight:                500;
$form-label-margin-bottom:              .8rem;

$form-floating-label-transform:         scale(.75) translateY(-.8rem) translateX(0.5rem);

$form-floating-label-opacity:           1;

$form-floating-height:                  64px;
$form-floating-padding-y:               1.25rem;

$form-floating-input-padding-t:         1rem;
$form-floating-input-padding-b:         1rem;



$nav-link-padding-x:                1rem;
$nav-link-color:                    inherit;
$nav-link-hover-color:              inherit;


$lead-font-size:              20px;
$font-weight-bolder:          500;

// BREADCRUMBS

$breadcrumb-divider-color:          inherit;
$breadcrumb-active-color:           inherit;

//CARDS

$card-spacer-x:                     0;
$card-border-width:                 1px;
$card-border-radius:                0;
$card-border-color:                 transparent;
$card-cap-padding-y:                32px;
$card-cap-padding-x:                0;
$card-cap-bg:                       transparent;
$card-bg:                           transparent;


//DROPDOWNS

$dropdown-color:                    currentColor;
$dropdown-border-width:             0;
$dropdown-box-shadow:               none;
$dropdown-link-hover-bg:            none;
$dropdown-padding-x:                1.5rem;
$dropdown-padding-y:                1.5rem;
$dropdown-min-width:                7rem;
$dropdown-link-active-color:        inherit;
$dropdown-link-active-bg:           transparent;


//Badge
// $badge-font-size:                   .8em;
$badge-font-weight:                 500;
// $badge-color:                       $white;
// $badge-padding-y:                   .35em;
// $badge-padding-x:                   .65em;
// $badge-border-radius:               $border-radius;


$caret-width:                 .3em;
$caret-vertical-align:        $caret-width * .85;
$caret-spacing:               $caret-width * 1.5;



//ACCORDION

$accordion-padding-y:                     1.7rem;
$accordion-padding-x:                     2rem;
$accordion-border-width:                  0;
$accordion-body-padding-y:                4rem;
$accordion-body-padding-x:                4rem;
$accordion-button-bg:                     transparent;
$accordion-button-active-bg:              inherit;
$accordion-button-active-color:           inherit;
$accordion-button-focus-border-color:     none;
$accordion-button-focus-box-shadow:       0;
$accordion-icon-width:                    28px;

// $accordion-bg:                            rgba(255, 255, 255, 0.7);
// $accordion-button-active-bg:              rgba(255, 255, 255, 1);

$accordion-button-icon:         url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23000000'%3E%3Cpath d='M0 0h24v24H0V0z' fill='none'/%3E%3Cpath d='M18 13h-5v5c0 .55-.45 1-1 1s-1-.45-1-1v-5H6c-.55 0-1-.45-1-1s.45-1 1-1h5V6c0-.55.45-1 1-1s1 .45 1 1v5h5c.55 0 1 .45 1 1s-.45 1-1 1z'/%3E%3C/svg%3E");
$accordion-button-active-icon:  url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23000000'%3E%3Cpath d='M0 0h24v24H0V0z' fill='none'/%3E%3Cpath d='M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z'/%3E%3C/svg%3E");



$offcanvas-transition-duration:        .3s;




$offcanvas-bg-color:               $s1;
$offcanvas-color:                  $o490;

$offcanvas-horizontal-width:        440px;



$btn-close-width:            1em;
$btn-close-focus-shadow:     0 0 0 0.25rem rgb(0 0 0 / 25%);
$btn-close-opacity:          .5;
$btn-close-hover-opacity:    .75;
$btn-close-focus-opacity:    1;
$btn-close-color:            currentColor;

$nav-tabs-link-active-color:        currentColor;
$nav-tabs-border-radius:            32px;


$progress-bar-transition:           none;



$form-check-input-width:                  1.2em;
$nav-tabs-border-width:             0;

$form-check-input-border:                 1px solid var(--dark);
$form-switch-color:               $o4;
$form-check-input-checked-bg-color:       $o1;
$form-check-input-focus-border:           $o4;
$form-check-input-focus-box-shadow:       $f4;
$form-switch-focus-color:         $o4;

// $form-text-color:                       currentColor;

.form-switch .form-check-input {
  height: 1.2em;
}

// $form-switch-width:               2em;
// $form-switch-padding-start:       $form-switch-width + .5em;
// $form-switch-bg-image:            url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-color}'/></svg>");
// $form-switch-border-radius:       $form-switch-width;
// $form-switch-transition:          background-position .15s ease-in-out;
//
// $form-switch-focus-color:         $input-focus-border-color;
// $form-switch-focus-bg-image:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-focus-color}'/></svg>");
//
// $form-switch-checked-color:       $component-active-color;
// $form-switch-checked-bg-image:    url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-checked-color}'/></svg>");
// $form-switch-checked-bg-position: right center;



// 3. Include remainder of required Bootstrap stylesheets
// @import "../node_modules/bootstrap/scss/variables";
// @import "../node_modules/bootstrap/scss/mixins";

// 4. Include any optional Bootstrap components as you like
// @import "../node_modules/bootstrap/scss/root";
// @import "../node_modules/bootstrap/scss/reboot";
// @import "../node_modules/bootstrap/scss/type";
// @import "../node_modules/bootstrap/scss/images";
// @import "../node_modules/bootstrap/scss/containers";
// @import "../node_modules/bootstrap/scss/grid";
// @import "../node_modules/bootstrap/scss/modal";
// @import "../node_modules/bootstrap/scss/utilities";
// @import "../node_modules/bootstrap/scss/buttons";
// @import "../node_modules/bootstrap/scss/nav";
// @import "../node_modules/bootstrap/scss/navbar";

@import "../../../node_modules/bootstrap/scss/bootstrap";

// 5. Add additional custom code here

.form-check-label {
  padding-top: 1px;
}

.img-thumbnail {
  max-width: 240px;
  width: 100%;
}

.btn {
  &:focus-visible {
    outline: 0;
  }
}

.z-n1 {
  z-index: -1;
}

.z-1 {
  z-index: 1;
}

.z-2 {
  z-index: 2;
}

.z-3 {
  z-index: 3;
}

.z-4 {
  z-index: 4;
}

// @mixin container-fluid($padding-x: $container-padding-x) {
//   width: 100%;
//   // max-width: 1800px;
//   padding-right: $padding-x*4;
//   padding-left: $padding-x*4;
//   margin-right: auto;
//   margin-left: auto;
// }
//
// .container-fluid {
//   @include container-fluid();
//
//   @include media-breakpoint-down(sm) {
//     padding-left: 24px;
//     padding-right: 24px;
//   }
// }

@mixin container-fluid($padding-x: $container-padding-x) {
  width: 100%;
  // max-width: 1800px;
  // padding-right: $padding-x*4;
  // padding-left: $padding-x*4;
  padding-left: 24px;
  padding-right: 24px;
  margin-right: auto;
  margin-left: auto;
}

.container-fluid {
  @include container-fluid();
  // max-width: 1800px;

  @include media-breakpoint-down(lg) {
    padding-left: 64px;
    padding-right: 64px;
  }

  @include media-breakpoint-down(md) {
    padding-left: 40px;
    padding-right: 40px;
  }

  @include media-breakpoint-down(sm) {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.border-inherit {
  border-color: inherit!important;
}

input {
  &:focus-visible {
    box-shadow: none;
    outline: 0!important;
  }
}

.container {
  // padding-left: 80px;
  // padding-right: 80px;
  // max-width: 1800px;

  @include media-breakpoint-down(lg) {
    padding-left: 64px;
    padding-right: 64px;
    max-width: 100%;
  }

  @include media-breakpoint-down(md) {
    padding-left: 32px;
    padding-right: 32px;
  }

  @include media-breakpoint-down(sm) {
    padding-left: 32px;
    padding-right: 32px;
  }

  // @include media-breakpoint-down(sm) {
  //   padding-left: 24px;
  //   padding-right: 24px;
  // }
}

.container--fluid-sm {
  @include media-breakpoint-down(sm) {
    padding-left: 0;
    padding-right: 0;
  }
}