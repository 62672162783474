section.sectors {
  will-change: auto!important;

  .world-map {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;

    .back-svg {
      position: absolute;
      left: 50%;
      transform: translate(-50%, -30%);
      max-width: 1440px;
      width: 100%;
      opacity: 0.1;
      top: 50%;
      height: auto;
    }
  }



  .block--cards {
    justify-content: flex-start;

    .block__content {
      display: block;
      height: auto;
    }
  }

  &.in-viewport {
    .section-header.active {
      opacity: 1;
      transform: translate(-50%, 0%);
      pointer-events: auto;

      // display: none;
    }
  }

  .section-header {
    position: fixed;
    top: 0;
    left: 50%;
    z-index: 1;
    display: inline-block;
    opacity: 0;
    pointer-events: none;
    transform: translate(-50%, -100%);

    transition-timing-function: cubic-bezier(.16,1,.3,1);
    transition-duration: .6s;
    transition-property: transform,opacity;

    width: 100%;

    .container-fluid {
      width: auto;
    }

    .section-header__inner {
      padding: 12px 40px;
      display: inline-block;
      background-color: rgba(white, .8);
      filter: drop-shadow(0 0.125rem 1.4375rem rgba(0,0,0,.08));
      -webkit-backdrop-filter: blur(15px);
      backdrop-filter: blur(15px);
      -webkit-box-shadow: 0 0 100px 0 rgb(25 20 20 / 2%), 0 0 100px 100px rgb(25 20 20 / 1%);
      box-shadow: 0 0 100px 0 rgb(25 20 20 / 2%), 0 0 100px 100px rgb(25 20 20 / 1%);
      margin-top: 0;
      text-align: center;
      padding: 12px 40px;
      display: flex;
      justify-content: center;
      align-items: center;

      @include large {
        padding: 16px 40px;
      }
    }

    h4 {
      @extend .serifBold;
      color: var(--dark);
      margin-bottom: 0;
      display: inline-block;
      font-size: 24px;
      white-space: nowrap;

      span {
        display: block;
        @extend h6;
        font-weight: 400;
        font-size: 12px;
        margin-bottom: 0px;
      }
    }
  }
}

.block--cards {
  background: none!important;

  // .lead {
  //   margin-bottom: 0;
  //   margin-left: 0;
  //
  //   @include large {
  //     font-size: 24px;
  //   }
  //
  //   strong {
  //     color: var(--medium);
  //
  //     @include large {
  //       font-size: 32px;
  //     }
  //   }
  // }

  .card {

    .details {
      visibility: hidden;
      opacity: 0;
      display: none;
      width: 100%;
      @extend .animate__animated;
      @extend .animate__faster;
    }

    &.active {

      .details {
        visibility: visible;
        opacity: 1;
        @extend .animate__fadeIn;

        @include large {
          display: block;
        }
      }
    }
  }

  .grid {
    pointer-events: none;
    position: relative;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;

  .grid__item {
    will-change: transform;
    cursor: pointer;
    will-change: transform;
    min-width: 160px;

    .label {
      line-height: 1.2;
      font-size: 14px;
      font-weight: 500;
      display: none;

      @include large {
        max-width: 160px;
        font-size: 14px;
        line-height: 1.3;
        background-color: var(--dark);
        color: var(--light);
        display: inline-block;

        position: absolute;
        z-index: 1;
        top: -8px;

        padding: 4px 8px;
        border-radius: 8px;
        font-weight: 500;

        rotate: -4deg;

        @supports (mask-image: paint(smooth-corners)) {
          border-radius: 8px;
          mask-image: paint(smooth-corners);
          --smooth-corners: 8;
        }
      }
    }

    h5 {
      font-size: 16px;

      @include large {
        font-size: 20px;
      }
    }

    // width: -webkit-fill-available;
    // position: absolute;

    svg {
      path {
        fill: currentColor!important;
      }
    }

    // @supports (mask-image: paint(smooth-corners)) {
    //   border-radius: 32px;
    //   mask-image: paint(smooth-corners);
    //   --smooth-corners: 6;
    // }



    .overlay-fill {
      background-color: var(--gridItemFill);
    }

    @include large-down {
      transform: none!important;
    }

    @include large {
      min-height: 88px;
    }

    img {
      opacity: 0;
      transition: .2s ease-in-out;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      right: 0;
      object-fit: cover;
      z-index: -1;
      height: 100%;
      width: 100%;
      display: none;

      @include large {
        display: block;
      }
    }

    // &:hover {
    //
    //   @include large {
    //     img {
    //       opacity: 1;
    //     }
    //   }
    // }

    //Shows images on cards-hover



    &.active {
      @include large {
        min-width: 280px;
        min-height: 270px;
        width: 100%;
        position: absolute;
        background-color: var(--medium);

        img {
          opacity: 0;
        }
      }
    }

    .grid__item__inner {
      display: flex;
      pointer-events: auto;
      // padding: 1.5em;
      height: 100%;
      align-items: center;
      justify-content: flex-end;
      flex-direction: row-reverse;
      overflow: hidden;
      border-radius: 64px 64px 64px 0px;

      background-color: var(--gridItemBase);
      color: var(--dark);

      @include large {
        text-align: center;
        border-radius: 40px;

        @supports (mask-image: paint(smooth-corners)) {
          border-radius: 0;
          mask-image: paint(smooth-corners);
          --smooth-corners: 8;
        }
      }

      @include large {
        padding: 1.3em 1.3em;
        justify-content: center;
        flex-direction: row;
        flex-direction: column;
        align-items: center;
        flex-wrap: nowrap;
      }

      h5 {
        line-height: 1.3;
      }

      .icon {
        position: absolute;
        right: 24px;

        @include large {
          display: none;
        }

        svg {
          height: 24px;
          width: 24px;
        }
      }
    }

    .percentage {
      margin-bottom: auto;
      display: flex;
      justify-content: flex-start;
      line-height: 1;
      margin-top: auto;
      flex-basis: 48px;

      @include large {
        margin-bottom: 0 !important;
        flex-basis: auto;
        font-size: 32px;
        justify-content: end;
      }

      @include xlarge {
        flex-basis: auto;
        font-size: 40px;
        justify-content: end;
      }

      &:after {
        content: "%";
        font-size: .6em;
        align-self: flex-end;
      }
    }
  }

  }
}

// .dot-holder, .exceed-text {
//   svg circle {
//     fill: currentColor;
//   }
// }
