.s1 {
  background-color: var(--modalBase);
  transition: background-color .45s ease;
}

.s2 {
  background-color: var(--boxBase);
  transition: background-color .45s ease;
}

#boxes {
  svg {
    width: 100px;
    height: 100px;
  }
}

@include large {
  #boxes {
    svg {
      width: 150px;
      height: 150px;
    }
  }
}