.mainHeader {
  height: $headerHeight !important;
  position: absolute;
  position: fixed; //apply when headroom is active
  top: 0;
  left: 0;
  right: 0;
  z-index: 11;
  align-items: center;
  display: flex;
  justify-content: start;
  border-radius: 9rem;
  color: white;
  pointer-events: none;
  // border-bottom: 1px solid rgba($s1, 0.2);

  .result-page &{
    color: currentColor;

    .tool-label {
      .badge {
        color: currentColor;
      }
    }

    .tool-label__title {
      border-left: 1px solid rgba(0, 0, 0, .1);
    }

    .navbar-brand {
      svg {
        path {
          fill: $o4;
        }
      }
    }
  }

  .headroom {
    height: $headerHeight;
    display: flex;
    align-items: center;
    left: 0;
    top: 0;
    width: 100%;

    border-radius: 9rem;
    z-index: 1;
    transition-timing-function: cubic-bezier(.16, 1, .3, 1);
    transition-duration: .6s;
    transition-property: background-color, top, left, width, opacity, transform;

    pointer-events: all;

    @include large {
      left: 1.5rem;
      top: 1rem;
      width: calc(100% - 3rem);
    }
  }

  .headroom--unfixed {
    position: relative;
    transform: translateY(0);
  }

  .headroom--scrolled {
    // background-color: rgba($s1, .9);
    // filter: drop-shadow(0 0.125rem 1.4375rem rgba(0,0,0,.08));
    // -webkit-backdrop-filter: blur(15px);
    // backdrop-filter: blur(15px);
    // -webkit-box-shadow: 0 0 100px 0 rgb(25 20 20 / 2%), 0 0 100px 100px rgb(25 20 20 / 1%);
    // box-shadow: 0 0 100px 0 rgb(25 20 20 / 2%), 0 0 100px 100px rgb(25 20 20 / 1%);

    transition-timing-function: cubic-bezier(.16, 1, .3, 1);
    transition-duration: .6s;
    transition-property: transform, opacity, margin;

    // left: 1.5rem;
    // top: 1rem;
    // width: calc(100% - 3rem);
    //
    // @include large {
    //   left: 2.5rem;
    //   top: 2.5rem;
    //   width: calc(100% - 5rem);
    // }

    &.headroom--pinned {
      transform: translateY(-100%);
    }
  }

  .headroom--unpinned {
    position: fixed;
    transform: translateY(-100%);
    opacity: 0;
    pointer-events: none;
  }

  .headroom--pinned {
    position: fixed;
    opacity: 1;
    transform: translateY(0%);

  }

  .navbar-brand {
    transition: opacity .15s ease-in-out;

    svg {
      path {
        fill: white;
      }
    }

    &:hover {
      opacity: .5;
    }

    &:focus-visible, &:focus, &:active {
      outline: 0;
    }
  }
}

.tool-label {
  display: none;
  align-items: center;

  @include small {
    display: flex;
  }

  .tool-label__title {
    font-size: 16px;
    margin: 0;
    padding-left: 12px;
    margin-top: 4px;
    font-weight: 500;
    border-left: 1px solid rgba(255, 255, 255, .1);
  }

  .badge {
    margin-top: 4px;
    margin-left: 8px;
  }
}



