:root {
--swiper-theme-color: currentColor;
--swiper-navigation-size: 16px;
--swiper-pagination-bullet-width:32px;
--swiper-pagination-bullet-size: 32px;
--swiper-pagination-bullet-horizontal-gap: 0px;
--swiper-pagination-bullet-inactive-opacity: 1;

@include large {
  --swiper-navigation-size: 24px;
  --swiper-pagination-bullet-horizontal-gap: 8px;
  }
}

.swiper-wrapper {
  box-sizing: border-box;
}

.swiper-slide {
  overflow: hidden;
}

.swiper-controls {
  // position: static;
  height: 120px;
  margin-bottom: 24px;

  @include large {
    margin-bottom: 40px;
  }

  .swiper-pagination-bullets {
    // position: absolute;
    position: relative;
    transform-origin: left;
    scale: .7;
    bottom: auto;

    @include large {
      scale: 1;
    }
  }

  .swiper-buttons {
    .swiper-button-prev, .swiper-button-next {
      z-index: 10;

      @include large {
        position: fixed;
      }
    }
  }
}

.swiper-pagination-bullets {
  display: flex;
  align-items: center;
  height: 120px;
  bottom: auto;
  overflow: visible;

  img {
    width: 96px;
    height: 96px;
    object-fit: cover;
    transform: translateX(-50%) translateY(-50%);
    transform-origin: unset;
    left: 50%;
    top: 50%;
    position: relative;
    padding: 8px;
    overflow: hidden;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.09);
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-radius: 24px;
  }

  .sdg-small-cards {
    img {
      --smooth-corners: 4;
      -webkit-mask-image: paint(smooth-corners);
      mask-image: paint(smooth-corners);
    }
  }

  .swiper-pagination-bullet, .swiper-pagination-bullet img {
    transition-property: all!important;
    transition-duration: 0.6s!important;
    transition-timing-function: cubic-bezier(.16,1,.3,1)!important;
    transition-delay: 0s!important;

    background: none;
  }

  .swiper-pagination-bullet:not(.swiper-pagination-bullet-active) {
    transform: scale(.55);
    rotate: -5deg;

    &:hover {
      translate: 0 -70%;
      transform: scale(.9);
    }

    &.swiper-pagination-bullet-active-prev, &.swiper-pagination-bullet-active-next {
      transform: scale(.9);

      &:hover {
        transform: scale(1.3);
        translate: 0 -100%;
      }
    }

    &.swiper-pagination-bullet-active-prev-prev, &.swiper-pagination-bullet-active-next-next {
      transform: scale(.7);

      &:hover {
        transform: scale(1.1);
      }
    }

    &.swiper-pagination-bullet-active-next, &.swiper-pagination-bullet-active-next-next {
      z-index: 6;
      rotate: 5deg;

      ~ .swiper-pagination-bullet {
        rotate: 5deg;
      }
    }

    &.swiper-pagination-bullet-active-next-next {
      z-index: 5;

      ~ .swiper-pagination-bullet {
        z-index: 4;

        + .swiper-pagination-bullet {
          z-index: 3;

          + .swiper-pagination-bullet {
            z-index: 2;

            + .swiper-pagination-bullet {
              z-index: 1;
            }
          }
        }
      }
    }
  }

  .swiper-pagination-bullet-active {
    pointer-events: none;
    z-index: 7;
    transform: scale(.9);

    @include large {
      transform: scale(1);
    }

    img {
      visibility: visible;
      width: 120px;
      height: 120px;
      opacity: 1;
    }
  }

}



.swiper-button-next, .swiper-button-prev {
  height: 36px;
  width: 36px;
  background-color: rgba(0,0,0,.1);
  border-radius: 64px;
  font-weight: 900;
  transform: translateY(-50%);
  transition: all ease-in-out .15s;
  color: currentColor;
  opacity: .5;

  @include large {
    height: 56px;
    width: 56px;
    scale: .95;

    &:hover {
      opacity: 1;
      scale: 1;
    }
  }
}

.swiper-button-next {
  right: 16px;
  top: 120px;

  @include large {
    right: 24px;
    top: 50%;
  }
}

.swiper-button-prev {
  left: 16px;
  top: 120px;

  @include large {
    left: 24px;
    top: 50%;
  }
}

.swiper-button-disabled {
  opacity: 0!important;
}
