.grid {

  .grid__item, .sectors__item {
    color: white;
  }

  // @include large {
  //   max-height: 500px;
  // }

  .overlay-fill {
    display: none;
  }

  @include large-down {
    height: auto !important;
    display: flex!important;
    flex-direction: column;
    row-gap: 8px!important;
    column-gap: 8px!important;

    .sectors__item, .grid__item {

      .content {
        padding: 16px 24px;
        border-radius: 64px 64px 64px 0px;
        height: 100%;
      }

      .overlay-fill {
        position: absolute;
        bottom: 0;
        left: 0;
        top: 0;
        width: 56%;
        background-color: transparent;
        z-index: -1;
        display: block;
      }

      // &:not(:last-child) {
      //   margin-bottom: 8px;
      // }

      .base-info {
        flex-direction: row;
        align-items: center;

        .label {
          line-height: 1.2;
          padding-right: 16px;
          font-weight: 500;
        }

        .percentage {
          min-width: 64px;
          text-align: right;
        }
      }
    }
  }
}



.sectors__item, .grid__item {
  // transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  // transition: all 0.15s;

  @include large-down {
    position: relative !important;
    left: auto !important;
    width: 100% !important;
    height: auto !important;
    top: auto !important;
  }


  &:hover, &.active {
    .content {
      filter: brightness(90%);
      scale: 1.01;
    }
  }

  .content, .grid__item__inner {
    padding: 20px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    cursor: pointer;
    will-change: transform;
    // -webkit-transition: all .15s cubic-bezier(0.7, 0, 0.3, 1);
    // transition: all .15s cubic-bezier(0.7, 0, 0.3, 1);
  }

  .content {
    border-radius: 32px;

    transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1)!important;
    transition-property: scale, filter!important;
    transition-duration: .15s!important;

    @supports (mask-image: paint(smooth-corners)) {

      @include large {
        border-radius: 0;
        mask-image: paint(smooth-corners);
        --smooth-corners: 14;
      }
    }
  }

  .details {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    padding: 24px;
  }

  .base-info {
    transition: 0.2s;
    transition-delay: 0.2s;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;

    .label {
      line-height: 1.3;
    }
  }

  .percentage {
    white-space: nowrap;
    display: flex;
    justify-content: end;

    &:after {
      content: "%";
      display: inline-block;
      font-size: 0.6em;
      margin-left: 2px;
      align-self: flex-end;
    }
  }

  &.active::before {
    content: ' ';
    position: fixed;
  }
}


///////grid--tabs

.grid--tabs {

  .sectors__item {
    .content {
      h3 {
        @include large {
          font-size: 40px;
        }
      }
    }
  }
}


///////GRID SDGs

.block--cards .grid--sdg {

  .grid__item {
    position: relative;
    transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1)!important;
    transition-property: scale, filter!important;
    transition-duration: .15s!important;
    min-width: auto!important;
    color: white!important;

    @include large {
      aspect-ratio: 1/1;
    }

    .grid__item__inner {
      color: white;

      .overlay-fill {
        background-color: rgba(0,0,0,.3);
      }
      @include large {
        padding: 12px;
      }

      &:after {
        background-color: white;
        display: inline-block;
        content: '';
        mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-plus-circle' viewBox='0 0 16 16'%3E%3Cpath d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'/%3E%3Cpath d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'/%3E%3C/svg%3E") no-repeat 50% 50%;
        mask-size: 3em;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        scale: .9;

        transition-timing-function: cubic-bezier(.16,1,.3,1);
        transition-duration: .3s;
        transition-property: opacity;
      }

      .percentage {

        @include large {
          display: none;
        }
      }
    }

    .overlay {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      opacity: 0;
      transition: 0.2s;
      z-index: 1;

      svg {
        path{
        stroke: #ffffff;
        }
      }
    }

    @include large {
      &:hover {
        // scale: 1.02;
        // filter: brightness(95%);
        filter: brightness(90%);
        scale: 1.01;

        .grid__item__inner {

          &:after {
            opacity: 1;
            scale: 1;
          }
        }

        img {
          opacity: .1!important;
        }
      }

      &:active {
        scale: .98;
      }
    }
  }
}
