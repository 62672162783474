
  .widget-is-sticky {
    .widget {
      opacity: 1;
      transform: translateY(0);
      pointer-events: auto;
    }
  }

  .widget {
    position: fixed;
    bottom: 0;
    z-index: 20;
    right: 0;
    opacity: 0;
    transform: translateY(100%);
    transition-timing-function: cubic-bezier(.16, 1, .3, 1);
    transition-duration: .6s;
    transition-property: transform, opacity;
    margin-bottom: 8px;
    margin-right: 8px;
    position: fixed;
    right: 0;
    background: #fff;
    border-radius: 45px;
    padding: 16px 16px 16px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    @extend .gap-3;
    box-shadow: 0px 2px 24px rgb(0 0 0 / 4%);

    @include large {
      margin-bottom: 24px;
      margin-right: 24px;
    }

    @include large-down {
      // margin: 0;
      // width: 100%;
      // border-radius: 0;
      padding: 8px 8px 8px 24px;
      // justify-content: space-between;
    }

    p {
      font-size: 14px;
      margin: 0;
      line-height: 1.4;
    }

    button {
      border-radius: 9rem;
      border: 0;
      padding: 8px 24px;

      height: 48px;
      display: inline-block;
      position: relative;
      text-decoration: none;
      border-radius: 24px;

      padding: 10px 20px 10px 20px;
      font-size: 12px;
      background-color: var(--dark);
      color: var(--light);

      &:hover {
        background-color: var(--medium);
      }
    }
  }
