// .modal-backdrop.show:nth-of-type(even) {
//     z-index: 1055 !important;
// }


.modal-backdrop {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(26, 26, 26, .6);

  &.show {
    opacity: 1;
  }
}

.modal {
  .spinner-border {
    top: 10vh;
    position: relative;
  }

  .modal-header {
    border-bottom: none;
    padding: 24px;
    text-align: center;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    pointer-events: none;

    @include large {
      padding: 32px 32px 0 32px;
    }
  }

  .modal-content {
    overflow: hidden;
  }
}

.modal-xl {

  .modal-body {
    margin-bottom: 0;
    position: static;
    line-height: 1.6;
    // font-size: 14px;
    padding-top: 64px;
    padding-bottom: 64px;

    @include large {
      font-size: 16px;
    }

    a:not(.btn) {
      color: currentColor;
      font-weight: 500;
    }

    @include large {
      padding-top: 96px;
      padding-bottom: 96px;
    }
  }

  .modal-title {
    opacity: .4;
    position: absolute;
    top: 32px;

    @include large {
      position: relative;
      top: 0;
    }
  }
}

.modal-backdrop:not(#swiper-backdrop), #swiper-backdrop.show {
  z-index: 1055 !important;
}

//////////////////////////
.modal--calendly {

  .calendly-inline-widget {
    background-color: $s1;
  }

  .modal-header, .modal-footer {
    position: relative;
    margin: 0 auto;
    width: 100%;
    max-width: 800px;
    padding: 24px 32px;
    background-color: $s1;
    pointer-events: all;
    // border-bottom: 1px solid #E1E0E3;
  }

  .modal-content {
    background-color: transparent;
    padding: 0;

    .modal-body {
      padding: 0;
    }
  }
}

//////////////////////////
.modal--contact {

  iframe {
    width: 100% !important
  }

  .modal-header {
    position: relative;
    margin: 0 auto;
    width: 100%;
    max-width: 800px;
    padding: 24px 32px;
    // background-color: $s1;
    pointer-events: all;
    // border-bottom: 1px solid #E1E0E3;
  }

  .modal-body {
    padding-top: 0;
  }
}


//////////////////////////
.modal--sectors {

  &:not(.sdg) {
    color: var(--dark);
  }

  // .swiper-slide {
  //   height: auto;
  // }

  .swiper-controls {
    height: 200px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }

  // .swiper {
  //   flex: 1 1 0;
  //   display: flex;
  //   align-items: center;
  // }

  .swiper-wrapper {
    align-items: center;
  }

  .modal-body {
    background-color: var(--modalBase);
    transition: background-color .15s ease;
  }

  .box {
    background: var(--modalBox);
    transition: background-color .15s ease;

    .box__media {
      height: 160px;
      width: 160px;
      object-fit: cover;
      padding: 0;
      overflow: hidden;
      -webkit-user-select: none;
      user-select: none;
      border-radius: 24px;
      overflow: hidden;

      display: none;

      @supports (mask-image: paint(smooth-corners)) {
        border-radius: 0;
        mask-image: paint(smooth-corners);
        --smooth-corners: 8;
      }
    }
  }
}


//////////////////////////
.modal--experts {

  .modal-content {
    p {
      font-size: 15px;
    }
  }

  h2 {
    @include large {
      font-size: 48px;
    }
  }

  h3 {
    @include small {
      font-size: 32px;
    }
  }

  .lead {
    font-size: 18px;
  }

  .pictogram {
    max-width: 96px;
    right: 0;
    top: -80px;

    @include small {
      max-width: 120px;
    }

    @include large {
      max-width: 160px;
    }
  }

  .box {
    background-color: #faf9f7;
    box-shadow: 0 1px 80px rgb(0 0 0 / 8%);
  }
}



.modal-media {
  width: 100%;
  background-size: cover;
  background-position: 50% 100%;
  height: 400px;
  position: relative;

  @include media-breakpoint-up(sm) {
    background-position: 50% 75%;
  }

  @include media-breakpoint-up(lg) {
    min-height: 70vh;
    height: 100%;
    background-position: 50%;
  }

  
}