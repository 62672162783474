.block--msci {
  display: block!important;

  &.in-viewport{
    .progress {
      opacity: 1;
    }
  }

  .progress {
    height: 8px;
    background-color: transparent;
    border-radius: 0;
    opacity: 0;

    .progress-bar {
      border-radius: 0;
      background-color: var(--dark);
    }
  }

  .msci-base {
      overflow: visible;
  }

  .company-logos {
    max-width: 240px;
    width: 100%;
    height: 160px;
    margin: auto;

    @include small {
      height: 200px;
    }
  }

  .company-logos__item {
    max-height: 48px;
  }

  #rounded-text {
    textPath {
      text-transform: uppercase;
      letter-spacing: .6px;
      fill: var(--dark);
      font-weight: 600;
      font-size: 14px;
    }
  }
}
