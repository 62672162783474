.nav-tabs {
  margin-bottom: -32px;
  flex-wrap: nowrap;

  li {
    // width: 50%;

    button {
      padding: 16px 24px 48px;
      width: 100%;
      @extend h3;

      @include large {
        padding: 24px 40px 48px;
      }

      &:focus-visible {
        outline: 0;
      }
    }
  }
}

.tab-content {
  position: relative;
  z-index: 1;
  padding: 24px;
  border-radius: 32px;

  @include large {
    padding: 40px;
  }
}

// .tab-pane {
//   min-height: 410px;
// }

.tab-content {
  display: flex;
}

.tab-content > .tab-pane {
  display: block;
  visibility: hidden;
  margin-right: -100%;
  width: 100%;
}

.tab-content > .active {
  visibility: visible;
}
