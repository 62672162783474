.tooltip-info {
  transition: .15s ease-in all;
  cursor: default;
  display: inline-flex;
  align-items: center;

  &:hover {
    opacity: .5;
  }

  .tooltip-info__icn {
    height: 24px;
    margin-left: 3px;
    margin-right: 3px;
    opacity: 1;
    transition: .15s ease-in all;
  }
}


.dynamic-tooltip {
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  display: inline-block;
  width: fit-content;
  position: relative;
  border-radius: 20px;

  &.animate-opacity {
    opacity: 0;
    visibility: hidden;
  }

  &.animate-opacity.active {
    opacity: 1;
    transition: 0.3s ease-in;
    visibility: visible;
  }

  &.dynamic-tooltip--left {
    &:after {
      left: 32px;
      rotate: 55deg;
    }
  }

  &.dynamic-tooltip--right {
    &:after {
      left: auto;
      right: 32px;
      rotate: 40deg;
    }
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    background: white;
    width: 16px;
    height: 16px;
    rotate: 45deg;
    transform: translateY(50%);
    border-radius: 0 0 4px 0;
  }

  // @supports (mask-image: paint(smooth-corners)) {
  //   border-radius: 8px;
  //   mask-image: paint(smooth-corners);
  //   --smooth-corners: 8;
  // }

  .dynamic-tooltip__inner {
    padding: 12px 24px;
    position: relative;
    z-index: 1;


    p {
      font-size: 13px;
      font-weight: 500;
      margin: 0;
      line-height: 1.3;
    }

    h6 {
      font-size: 18px;
      font-weight: 600;
    }
  }
}

