
.checkbox + div {
  display: inline-block;
  vertical-align: middle;
  transform: translate3d(0, 0, 0);
}
.checkbox + div {
  position: relative;
  width: 36px;
  min-width: 36px;
  height: 36px;
  border-radius: 50%;
  transform: scale(1);
  vertical-align: middle;
  border: 1px solid #B9B8C3;
  transition: all 0.2s ease;

  @include media-breakpoint-down(lg) {
    width: 28px;
    min-width: 28px;
    height: 28px;
  }
}

.checkbox + div:before {
  content: "";
  width: 100%;
  height: 100%;
  background: $o1;
  display: block;
  transform: scale(0);
  opacity: 1;
  border-radius: 50%;
  transition-delay: 0.2s;
}

.checkbox + div:first-child {
  border-color: #3c53c7;
}

.checkbox.checked + div {
  border-color: $o1;
  background: $o1;
  animation: check 0.6s ease;
}

.checkbox.checked + div:before {
  transform: scale(2.2);
  opacity: 0;
  transition: all 0.6s ease;
}

.checkbox.checked + div svg {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  @include media-breakpoint-down(lg) {
    transform: translate(-50%, -50%) scale(.8);
  }
}

.checkbox.checked + div {
  color: #B9B8C3;
  transition: all 0.3s ease;
}

@keyframes check {
  50% {
    transform: scale(1.2);
  }
}
[data-selected-list="b"] {
  [data-checked="false"] {
    opacity: 0.4;
    -webkit-text-decoration-line: line-through;
    text-decoration-line: line-through;
  }
}


//
//.cbx {
//  -webkit-user-select: none;
//  user-select: none;
//  -webkit-tap-highlight-color: transparent;
//  cursor: pointer;
//}
//.cbx span {
//  display: inline-block;
//  vertical-align: middle;
//  transform: translate3d(0, 0, 0);
//}
//.cbx span:first-child {
//  position: relative;
//  width: 36px;
//  height: 36px;
//  border-radius: 50%;
//  transform: scale(1);
//  vertical-align: middle;
//  border: 1px solid #B9B8C3;
//  transition: all 0.2s ease;
//}
//.cbx span:first-child svg {
//  position: absolute;
//  z-index: 1;
//  top: 11px;
//  left: 10px;
//  transition: all 0.3s ease;
//  transition-delay: 0.1s;
//  transform: translate3d(0, 0, 0);
//}
//.cbx span:first-child:before {
//  content: "";
//  width: 100%;
//  height: 100%;
//  background: #2F3A71;
//  display: block;
//  transform: scale(0);
//  opacity: 1;
//  border-radius: 50%;
//  transition-delay: 0.2s;
//}
//
//.cbx:hover span:first-child {
//  border-color: #2F3A71;
//}
//
//.inp-cbx:checked + .cbx span:first-child {
//  border-color: #2F3A71;
//  background: #2F3A71;
//  animation: check 0.6s ease;
//}
//.inp-cbx:checked + .cbx span:first-child svg {
//  stroke-dashoffset: 0;
//}
//.inp-cbx:checked + .cbx span:first-child:before {
//  transform: scale(2.2);
//  opacity: 0;
//  transition: all 0.6s ease;
//}
//
//
//@keyframes check {
//  50% {
//    transform: scale(1.2);
//  }
//}