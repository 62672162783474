.btn-velvet {
  background-color: $v1;
  color: white
}

.btn--forward {
  @include link-arrow;
  @extend .gap-1;

  &:before {
    display: none;
  }
}

.btn-rounded {
  border-radius: 100px;
  position: relative;

  &:hover {
    background-color: #eee;
  }

  &:active {
    top: 1px;
  }
}

.btn--back {
  @include link-arrow;
  @extend .gap-1;

  &:before {
    rotate: 180deg;
  }

  &:after {
    display: none;
  }
}

.btn--svg {
  text-decoration: none;
  border-radius: 100px;
  padding-left: 20px;

  &.btn-light {
    background-color: transparent;
    box-shadow: none;

    &:focus, &:active {
      color: currentColor;
    }

    &:hover {
      background-color: rgba(255,255,255,.1);
    }
  }

  &:hover {
    background-color: rgba(255,255,255,.5);
  }
  line, polyline {
    stroke: $brown;
  }
}

.btn-link {
  font-weight: 500;

  #impact-app & {
    text-decoration: underline var(--medium) 2px;
    -webkit-text-decoration-line: underline !important;
    -webkit-text-decoration-color: var(--medium) !important;
    -webkit-text-decoration-style: solid !important;
    -webkit-text-decoration-thickness: 2px !important;

    &:hover {
      text-decoration: none;
    }
  }

  &:hover {
    text-decoration: none;
  }

  &:focus {
    box-shadow: none;
  }
  
}

.btn-primary {
  background-color: var(--dark);
  color: var(--light);

  &:hover {
    // background-color: var(--medium);
    color: var(--light);
  }
}

.jumping {
  animation: jumping .45s ease-in alternate infinite;
}


.btn--circle {
  border-radius: 100px;
  line-height: 1;
  padding: 8px;
  transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  transition: scale .15s;

  &:hover {
    scale: 1.1;
  }

  &:active {
    scale: .9;
  }

  svg {
    height: 32px !important;
    width: 32px !important;
  }
}

.submit {
  height: 72px;
  width: 72px;
  border-radius: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  transition: all .25s ease-in-out;
  background: $s1;
  // transform: translateX(-70%);

  &:active {
    transform: scale(.95);
    transform-origin: center;
  }

  @include large {
    height: 96px;
    width: 96px;
  }

  svg {
    position: relative;
    z-index: 1;
    width: 24px;
    height: 24px;

    @include large {
      width: 36px;
      height: 36px;
    }

    path {
      fill: #36362f;
    }
  }

  &:hover {
    background: $s2;

    svg {
      position: relative;
      z-index: 1;

    }
  }
}

button.btn-task {
  font-size: 18px;

  @include media-breakpoint-down(lg) {
    font-size: 15px;
  }
}

.btn-task {
  border: 1px solid transparent;
  border-radius: 24px;
  color: currentColor;
  cursor: pointer;
  text-align: start;
  // font-size: 18px;
  line-height: 1.3;
  transition: transform ease-in-out .15s;
  background-color: rgba(255,255,255,.7);

  .btn-close {
    font-size: .8rem;
    padding: 12px;

    &.btn-sm {
      font-size: .6rem;
      padding: 8px;
    }
  }

  &:hover {
    background-color: rgba(255,255,255,1);
    color: inherit;
  }

  @include media-breakpoint-down(lg) {
    font-size: 15px;

    .drivers__item--tooltip {
      svg {
        // height: 24px;
      }
    }

    .btn-task__icon {
      min-width: 24px;
      max-width: 24px;
    }
  }

  .btn-close {
    cursor: pointer;
  }

  

  &.btn-task__white {
    background: #fff;
  }

  .checked-task {
    opacity: 0;
    transition: opacity 0.15s ease-in-out;
  }

  .offcanvas & {
    // cursor: default;
    p {
      font-size: 15px;
    }

    &:hover {
      // border-color: transparent;
    }
  }

  &:focus {
    box-shadow: none;
  }


  &.active {
    background-color: rgba(255,255,255,1);
    // border: 1px solid $o4;
    // border: 1px solid rgba(24,35,97,.4);
    // background-color: $s150!important;

    .checked-task {
      opacity: 1;

    }
  }
}

button.btn-task:not(.border-0) {
  @include large {
    &:hover {
      // border: 2px solid #182362;
      transform: scale(1.005);
    }

    &:active {
      transform: scale(1);
    }
  }
}


.btn-close {
  background-size: 1em;
  background-color: rgba(0,0,0,.1);
  border-radius: 24px;
  z-index: 1;
  transform: scale(.95);
  transition: all ease-in-out .15s;
  pointer-events: auto;
  padding: 8px;

  @include large {
    padding: 12px;
  }

  &:hover {
    transform: scale(1);
  }
}

.btn--info {
  transition: all ease-in-out .15s;
  outline: 0;

  .btn-task & {
    @include media-breakpoint-down(lg) {
      svg {
        width: 28px;
        height: 28px;
      }
    }
  }

  &:focus {
    box-shadow: none;
  }
  &:hover {
    transform: scale(1.05);

    // svg path {
    //   fill: $o1;
    // }
  }
}