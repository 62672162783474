.card {
  word-wrap: normal;

  .card-media {

    display: flex;
    align-items: end;
    justify-content: center;

    .sdg-item {
      border-radius: 8px;

      @supports (mask-image: paint(smooth-corners)) {
        border-radius: 8px;
        mask-image: paint(smooth-corners);
        --smooth-corners: 8;
      }
    }

    @include large {
      height: 200px;
      justify-content: flex-start;
    }
  }
}

.negative-impact-alert {
  .card {
    background: #fdeeee !important;
    border: 1px solid red;
  }
  .theme-text {
    display: block!important;
  }
}

.card---beige {
  background: $s1;
}