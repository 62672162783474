$size: 8px;
$width: 70;
$white: rgba(255, 255, 255, .4);
$animation: dotFlashing 1s infinite linear alternate;

.loading:before {
  content: "";
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  background-color: $s2;
  top: 0;
  left: 0;
  z-index: 1000;
  pointer-events: all;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.loading:after {
  content:"";
  animation: loading-progress 2s;
  transform-origin: left;
  top: 0;
  left: 0;
  position: absolute;
  height: 8px;
  width: 100%;
  background-color: $s3;
  z-index: 1001;
}


@keyframes loading-progress {
    0% {
        transform: scale3d(0, 1, 1);
    }
    100% {
        transform: scale3d(1, 1, 1);
    }
}

.dot-flashing {
  position: absolute;
  width: $size;
  height: $size;
  border-radius: $size;
  background-color: $white;
  color: $white;
  animation: $animation;
  animation-delay: .5s;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.dot-flashing::before, .dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  animation-fill-mode: none;
}

.dot-flashing::before {
  left: -$size*2;
  width: $size;
  height: $size;
  border-radius: $size;
  background-color: $white;
  color: $white;
  animation: $animation;
}

.dot-flashing::after {
  left: $size*2;
  width: $size;
  height: $size;
  border-radius: $size;
  background-color: $white;
  color: $white;
  animation: $animation;
  animation-delay: 1s;
}


@keyframes dotFlashing {
  0% {
    background-color: rgba(255, 255, 255, .4);
  }

  50% {
    background-color: rgba(255, 255, 255, 1);
  }

  100% {
    background-color: rgba(255, 255, 255, .4);
  }
}

.cards-loader {
  //background-color: $s1;
}

.spin {
  position: absolute;
  top: calc(15vh - 20px);
  left: 50%;
  width: 40px;
  height: 40px;
  margin: 0 0 0 -20px;
  border: 4px solid var(--dark);
  border-bottom-color: var(--light);
  border-radius: 50%;
  animation: animLoader 0.8s linear forwards infinite;
}


@keyframes animLoader {
  to {
    transform: rotate(360deg);
  }
}


// .spin {
//   width: 40px;
//   height: 40px;
//   margin: 100px auto;
//   background-color: var(--medium);
//
//   border-radius: 100%;
//   -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
//   animation: sk-scaleout 1.0s infinite ease-in-out;
// }
//
// @-webkit-keyframes sk-scaleout {
//   0% { -webkit-transform: scale(0) }
//   100% {
//     -webkit-transform: scale(1.0);
//     opacity: 0;
//   }
// }
//
// @keyframes sk-scaleout {
//   0% {
//     -webkit-transform: scale(0);
//     transform: scale(0);
//   } 100% {
//     -webkit-transform: scale(1.0);
//     transform: scale(1.0);
//     opacity: 0;
//   }
// }
