.business-dropdown {
  color: #333;

  .modal-content {
    background: transparent;
    box-shadow: none;
    overflow: visible;
    padding: 64px 0;

    .form-control {
      min-width: 300px;
    }
  }

  .modal-body {
    // max-width: 360px;
    width: 100%;
    margin: 0 auto;
    background-color: transparent;
    border-radius: 0;
    overflow: visible;
  }

  .swiper {
    overflow: visible;
  }

  .swiper-wrapper {
    height: auto;
  }

  .business-dropdown--item {
    cursor: pointer;
    background-color: white;
    border-radius: 100px;
    padding: 20px 56px 20px 24px;
    font-weight: 500;
    line-height: 1.4;

    transition: background-color .1s ease;

    &:hover {
      background-color: #fafafa;
    }

    &:not(.business-dropdown--back) {
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_1255_14733' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'%3E%3Crect width='24' height='24' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_1255_14733)'%3E%3Cpath d='M8.87309 17.1269C8.7282 16.982 8.65576 16.8064 8.65576 16.6C8.65576 16.3936 8.7282 16.218 8.87309 16.0731L12.9462 12L8.87309 7.9269C8.7282 7.78203 8.65576 7.6064 8.65576 7.4C8.65576 7.1936 8.7282 7.01797 8.87309 6.8731C9.01795 6.72822 9.19359 6.65578 9.39999 6.65578C9.60639 6.65578 9.78202 6.72822 9.92689 6.8731L14.4211 11.3673C14.5147 11.4609 14.5807 11.5596 14.6192 11.6635C14.6577 11.7673 14.6769 11.8795 14.6769 12C14.6769 12.1205 14.6577 12.2327 14.6192 12.3365C14.5807 12.4404 14.5147 12.5391 14.4211 12.6327L9.92689 17.1269C9.78202 17.2718 9.60639 17.3442 9.39999 17.3442C9.19359 17.3442 9.01795 17.2718 8.87309 17.1269Z' fill='%231C1B1F'/%3E%3C/g%3E%3C/svg%3E%0A");
      background-position: center right 16px;
      background-repeat: no-repeat;
    }

  }

  .business-dropdown--submit {
    position: absolute;
    right: 11px;
    top: 50%;
    transform: translateY(-50%);
    padding: 12px;
    border-radius: 50%;
    opacity: 0;
    // background: #000;
    visibility: hidden;
    transition: 0.3s ease-in-out;
    border: none;
    outline: none;
    box-shadow: none;

    &.active {
      opacity: 1;
      visibility: visible;

    }
  }

  .swiper-slide {
    opacity: 0 !important;
    padding: 0 16px;
    pointer-events: none;
  }

  .swiper-slide-active {
    opacity: 1 !important;
    pointer-events: all;
  }

  .swiper-creative .swiper-slide {
    overflow: auto;
    height: 100%;
  }

  input {
    // background: $s1;
    // border: none;
    // border-radius: 12px;
    // font-size: 16px;
    // padding: 12px 12px 12px 48px;


    &[type="search"] {
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_1059_11541' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='20' height='20'%3E%3Crect width='20' height='20' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_1059_11541)'%3E%3Cpath d='M15.6875 16.8958L11.125 12.3333C10.6667 12.6806 10.1701 12.941 9.63542 13.1146C9.10069 13.2882 8.54167 13.375 7.95833 13.375C6.44444 13.375 5.15625 12.8507 4.09375 11.8021C3.03125 10.7535 2.5 9.47222 2.5 7.95833C2.5 6.44444 3.03125 5.15625 4.09375 4.09375C5.15625 3.03125 6.44444 2.5 7.95833 2.5C9.47222 2.5 10.7535 3.03125 11.8021 4.09375C12.8507 5.15625 13.375 6.44444 13.375 7.95833C13.375 8.54167 13.2917 9.10069 13.125 9.63542C12.9583 10.1701 12.6944 10.6597 12.3333 11.1042L16.9167 15.7083C17.0833 15.875 17.1632 16.0729 17.1562 16.3021C17.1493 16.5313 17.0625 16.7292 16.8958 16.8958C16.7292 17.0625 16.5278 17.1458 16.2917 17.1458C16.0556 17.1458 15.8542 17.0625 15.6875 16.8958ZM7.95833 11.625C8.97222 11.625 9.83681 11.2674 10.5521 10.5521C11.2674 9.83681 11.625 8.97222 11.625 7.95833C11.625 6.93056 11.2708 6.05556 10.5625 5.33333C9.85417 4.61111 8.98611 4.25 7.95833 4.25C6.93056 4.25 6.05556 4.61111 5.33333 5.33333C4.61111 6.05556 4.25 6.93056 4.25 7.95833C4.25 8.98611 4.61111 9.85417 5.33333 10.5625C6.05556 11.2708 6.93056 11.625 7.95833 11.625Z' fill='%231C1B1F'/%3E%3C/g%3E%3C/svg%3E%0A");
      background-position: left 20px center;
      background-repeat: no-repeat;
    }
  }

  ::-webkit-scrollbar {
    width: 4px;
    height: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, .7);
    border-radius: 2px;
  }
}