body.loaded {
  .loading-screen {
    display: none;
  }
}

.loading-screen {

  &.wrap {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    height: 100vh;
    z-index: 9998;
    display: flex;
    background-color: $s2;
    will-change: transform;

    svg {
      will-change: transform;
      display: none;
    }

    h3 {
      color: $s4;
      margin-bottom: 12px;
      // @extend .serifBoldItalic;
      font-size: 48px;

      &:after {
        content:"%";
        // font-size: 0.7em;
      }
    }

    .progressBar {
      left: 50%;
      transform: translateX(-50%);
      bottom: 96px;
      border-radius: 50%;

      .progress {
        background-color: transparent;
      }

      .progress-bar {
        background-color: $s3;
        border-radius: 0;
      }
    }
  }
}
