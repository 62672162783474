// BASE TYPOGRAPHY
//-------------------------
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
//
@font-face {
  font-family: 'Formue Headline Medium';
  src: url('../fonts/Formue_Headline_Medium.eot');
  src: url('../fonts/Formue_Headline_Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Formue_Headline_Medium.woff2') format('woff2'),
  url('../fonts/Formue_Headline_Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Berlingske Serif';
  src: url('../fonts/BerlingskeSerif-Rg.eot');
  src: url('../fonts/BerlingskeSerif-Rg.eot?#iefix') format('embedded-opentype'),
  url('../fonts/BerlingskeSerif-Rg.woff2') format('woff2'),
  url('../fonts/BerlingskeSerif-Rg.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Berlingske Serif';
  src: url('../fonts/BerlingskeSerif-DBd.eot');
  src: url('../fonts/BerlingskeSerif-DBd.eot?#iefix') format('embedded-opentype'),
  url('../fonts/BerlingskeSerif-DBd.woff2') format('woff2'),
  url('../fonts/BerlingskeSerif-DBd.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Berlingske Serif';
  src: url('../fonts/BerlingskeSerif-It.eot');
  src: url('../fonts/BerlingskeSerif-It.eot?#iefix') format('embedded-opentype'),
  url('../fonts/BerlingskeSerif-It.woff2') format('woff2'),
  url('../fonts/BerlingskeSerif-It.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Berlingske Serif';
  src: url('../fonts/BerlingskeSerif-DBdIt.eot');
  src: url('../fonts/BerlingskeSerif-DBdIt.eot?#iefix') format('embedded-opentype'),
  url('../fonts/BerlingskeSerif-DBdIt.woff2') format('woff2'),
  url('../fonts/BerlingskeSerif-DBdIt.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}

.sans-serif {
  font-family: 'Formue Headline Medium';
  font-style: normal;
  font-stretch: normal;
}

.serifRegular {
  font-family: 'Berlingske Serif';
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0 !important;
}

.serifBold {
  font-family: 'Berlingske Serif';
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0 !important;
}

.serifItalic {
  font-family: 'Berlingske Serif';
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
  letter-spacing: 0 !important;
}

.serifBoldItalic {
  font-family: 'Berlingske Serif';
  font-style: italic;
  font-weight: 600;
  font-stretch: normal;
  letter-spacing: 0 !important;
}


// GENERAL
//-------------------------

html, body {
  font-size: 100%;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-family: 'IBM Plex Sans', sans-serif;
  overscroll-behavior-y: none;
}

body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  font-size: 16px;
  color: #222;
}

.gradient-text {
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  // background-image: linear-gradient(90deg,#2ca2b4,#5598de 24%,#7f87ff 45%,#f65aad 76%,var(--converse));
  background-image: linear-gradient(90deg, var(--converse), var(--medium) 50%, var(--dark) 100%, var(--light) 100%, var(--light));
  will-change: transform;
}


h1, h2, h3, h4, h5, h6 {
  @extend .serifBold;
  font-weight: 600;
  word-wrap: normal;
  line-height: 1.2;
  margin-bottom: 0;
  @extend .hyphens;
}

h1 {
  font-size: 48px;
  line-height: 1.15;

  text-shadow: 0px 2px 24px rgba(0, 0, 0, 0.1);

  // @include small {
  //   font-size: 48px;
  // }

  @include medium {
    font-size: 56px;
  }

  @include large {
    line-height: 1.15;
    font-size: 64px;
  }

  @include xxlarge {
    line-height: 1;
    font-size: 72px;
    font-size: min(max(36px, 4.5vw), 104px);
  }

  &.xlarge {
    font-size: min(max(80px, 7vw), 120px);
  }

  &.large {
    @include xxlarge {
      line-height: 1;
      font-size: 72px;
      font-size: min(max(36px, 5.4vw), 104px);
    }
  }
}

h2 {
  font-size: 36px;
  line-height: 1.2;

  @include small {
    font-size: 48px;
  }

  @include large {
    font-size: 56px;
  }

  &.medium {
    font-size: 48px;

    @include large {
      line-height: 1.1;
      font-size: 72px;
    }
  }

  &.large {
    font-size: 64px;

    @include large {
      font-size: min(max(40px, 8vw), 240px);
    }
  }
}

h3 {
  font-size: 32px;
  line-height: 1.3;

  @include large {
    line-height: 1.2;
    font-size: 40px;
  }
}

h4 {
  font-size: 24px;
  line-height: 1.3;

  &.large {
    font-size: 32px;

    @include media-breakpoint-down(sm) {
      font-size: 24px;
    }
  }
}

h5 {
  font-size: 18px;
  // font-weight: 500;
  line-height: 1.4;
  // font-family: 'IBM Plex Sans', sans-serif;

  @include large {
    font-size: 20px;
  }
}

h6 {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4;
  font-family: 'IBM Plex Sans', sans-serif;
}

.font-weight-medium {
  font-weight: 500;
}

.preamble {
  font-family: 'Berlingske Serif';
  font-size: 20px;
  line-height: 1.6;

  @include media-breakpoint-down(lg) {
    font-size: 16px;
  }
}

.hyphens {
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.lead {
  font-size: 16px;
  margin: 0 auto;

  // strong {
  //   font-weight: 600;
  // }

  @include small {
    font-size: 20px;
  }
}

p {
  line-height: 1.5;
  letter-spacing: .2px;
  font-size: 16px;

  // @include large {
  //   font-size: 18px;
  // }
}

ul li {
  line-height: 1.6;
  letter-spacing: .2px;
}

p strong, li strong {
  font-weight: 500;
}

.h1--large {
  font-size: 80px;
  font-weight: 400;

  .small {
    font-size: 0.35em;
    font-weight: 600;
  }

  @include medium {
    font-size: 160px;
  }
}

ul {
  margin-bottom: 0px;
}


.pennant {
  background-color: $s2;
  padding: 6px 10px;
  border-radius: 2px 2px 2px 0;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    bottom: -12px;
    left: 0;
    height: 12px;
    width: 12px;
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0H10L5 5L0.957876 9.04212C0.604395 9.39561 0 9.14526 0 8.64536V0Z' fill='%23EAE2D7'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-size: contain;
  }
}

strong.preamble {
  font-weight: 600;
}


.text-right {
  text-align: right;
}
